<script setup lang="ts">
import CurrencyInput from "./components/CurrencyInput.vue";
import { formatCurrency } from "~/utils/string/format-currency";

interface PeriodItem {
  months?: number;
  absolute?: boolean;
}

interface Props {
  interest: number;
  period: PeriodItem[];
  minInvestment?: number;
  resultDescription?: string;
}

const props = withDefaults(defineProps<Props>(), {
  minInvestment: 10000,
  resultDescription: undefined,
});

const amount = ref(props.minInvestment);

const getYield = ({ absolute, months }: PeriodItem) => {
  if (absolute) {
    return formatCurrency(amount.value * (1 + props.interest));
  }
  if (typeof months !== "number") {
    return "";
  }

  const deadline = months / 12;
  const yieldValue =
    amount.value * (Math.pow(1 + props.interest, deadline) - 1) + amount.value;
  return formatCurrency(yieldValue);
};
</script>

<template>
  <div
    class="flex flex-col border border-gray-300 rounded-xl gap-y-7 w-full lg:p-10"
  >
    <div class="flex">
      <div class="flex relative items-stretch w-full">
        <div class="h-[40px] lg:h-[70px]">
          <div
            class="border border-gray-300 border-r-0 h-full rounded-l-lg flex items-center justify-center px-2"
          >
            <span class="font-poppins font-medium">Valor:</span>
          </div>
        </div>
        <CurrencyInput
          v-model="amount"
          aria-label="Valor"
          class="border border-gray-300 rounded-r-lg min-h-[40px] pl-2 w-full font-poppins font-medium"
        />
      </div>
    </div>

    <div class="flex justify-center">
      <div
        class="flex flex-col gap-y-5 gap-x-5 w-full justify- items-center lg:flex-row lg:gap-y"
      >
        <div
          v-for="p in period"
          :key="p.months"
          class="flex flex-col justify-center items-center p-[10px] border border-gray-300 rounded-lg overflow-hidden truncate w-full"
        >
          <span class="font-poppins text-sm font-normal">
            {{ props.resultDescription || `Em ${p.months} meses você terá:` }}
          </span>
          <span class="font-poppins text-xl font-semibold truncate">
            {{ getYield(p) }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
